var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.loading && !_vm.profilePage && _vm.data.properties.filed_content != 'Hide')?_c('div',{style:(_vm.getElementStyle)},[(!_vm.isDefalutPos)?_c('div',[_c('div',{staticStyle:{"display":"flex"}},[(!_vm.data.properties.hideLabel)?_c('el-col',{staticStyle:{"display":"contents"},attrs:{"span":_vm.showLabel && !_vm.isDefalutPos ? 4 : _vm.data.description ? 20 : 24}},[(_vm.hasLabel && _vm.data.properties.filed_content != 'Hide')?_c('label',{staticStyle:{"margin-right":"5px"},style:(_vm.getLabelStyles),attrs:{"for":"text"}},[_vm._v(_vm._s(_vm.label || _vm.data.label))]):_vm._e(),(_vm.data.validations.required && _vm.hasLabel && !_vm.isView)?_c('span',{staticStyle:{"color":"red","font-weight":"bold","margin-right":"5px"}},[_vm._v(" * ")]):_vm._e(),_c('span',[(_vm.data.description)?_c('el-popover',{staticStyle:{"margin-right":"5px"},attrs:{"placement":"top-start","title":"Title","width":"200","trigger":"hover","content":_vm.data.description}},[_c('i',{staticClass:"el-icon-info",attrs:{"slot":"reference"},slot:"reference"})]):_vm._e()],1)]):_vm._e(),_c('el-col',{attrs:{"span":_vm.showLabel && !_vm.isDefalutPos ? 12 : 24}},[(
            !_vm.isView &&
            _vm.isList &&
            _vm.data.inputType == 'MULTI_LINE_TEXT' &&
            _vm.data.properties.filed_content != 'Hide'
          )?_c('el-input',{style:({ 'text-align': _vm.inputTextAlignment(), ..._vm.getStyle }),attrs:{"type":"textarea","rows":_vm.data.validations.rows,"placeholder":_vm.data.placeholder || _vm.data.label,"resize":"none","readonly":true,"disabled":_vm.isFromDocument ? false : _vm.checkReadonly()},on:{"change":_vm.applyFormRules},model:{value:(_vm.form[_vm.data.key]),callback:function ($$v) {_vm.$set(_vm.form, _vm.data.key, $$v)},expression:"form[data.key]"}}):_vm._e(),(
            !_vm.isView &&
            _vm.data.inputType == 'MULTI_LINE_TEXT' &&
            !_vm.isList &&
            _vm.data.input_type != 'RICH_TEXT' &&
            _vm.data.properties.filed_content != 'Hide'
          )?_c('el-input',{attrs:{"rows":_vm.data.validations.rows,"type":"textarea","resize":"none","placeholder":_vm.data.placeholder || _vm.data.label,"disabled":_vm.isFromDocument ? false : _vm.checkReadonly(),"readonly":_vm.readonly()},on:{"change":_vm.applyFormRules},model:{value:(_vm.form[_vm.data.key]),callback:function ($$v) {_vm.$set(_vm.form, _vm.data.key, $$v)},expression:"form[data.key]"}}):_vm._e(),(_vm.data.input_type == 'RICH_TEXT')?[(!_vm.isView && _vm.isView == false && !_vm.data.is_entity_variable)?_c('TextEditor',{attrs:{"editorData":_vm.form[_vm.data.key]},on:{"update:editorData":function($event){return _vm.$set(_vm.form, _vm.data.key, $event)},"update:editor-data":function($event){return _vm.$set(_vm.form, _vm.data.key, $event)}}}):_vm._e(),(
              (this.$route &&
                this.$route.name &&
                this.$route.name == 'ViewTemplate' &&
                _vm.data.is_entity_variable) ||
              (this.$route &&
                this.$route.name &&
                this.$route.name == 'PreviewEntityDataStep') ||
              _vm.data.is_entity_variable
            )?_c('p',{staticStyle:{"white-space":"pre-line"},style:(_vm.getStyle)},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.form[_vm.data.key])}})]):_vm._e()]:_vm._e(),(_vm.isView)?_c('p',{staticClass:"sidemenu",staticStyle:{"white-space":"pre-line"},style:(_vm.getStyle)},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.form[_vm.data.key])}})]):_vm._e()],2)],1)]):_c('el-row',{attrs:{"align":"middle"}},[(!_vm.data.properties.hideLabel)?_c('el-col',{ref:"colRef",staticStyle:{"display":"contents"},style:(_vm.computedStyles),attrs:{"span":_vm.showLabel && !_vm.isDefalutPos ? 4 : _vm.data.description ? 18 : 20}},[(_vm.hasLabel && _vm.data.properties.filed_content != 'Hide')?_c('label',{style:(_vm.getLabelStyles),attrs:{"for":"text"}},[_vm._v(_vm._s(_vm.label || _vm.data.label))]):_vm._e(),(_vm.data.validations.required && _vm.hasLabel && !_vm.isView)?_c('span',{staticStyle:{"color":"red","font-weight":"bold"}},[_vm._v(" * ")]):_vm._e(),_c('span',[(_vm.data.description)?_c('el-popover',{attrs:{"placement":"top-start","trigger":"hover","content":_vm.data.description}},[_c('i',{staticClass:"el-icon-info",attrs:{"slot":"reference"},slot:"reference"})]):_vm._e()],1),(
          _vm.data.ai_settings &&
          _vm.data.ai_settings.selectedSourceField &&
          _vm.data.ai_settings.runOnAction.includes('OnClick')
        )?_c('el-button',{staticClass:"realtym-btn",attrs:{"disabled":_vm.checkIsGenerateDisabled},on:{"click":function($event){return _vm.generateAIForField(_vm.data)}}},[_vm._v(" "+_vm._s(_vm.data.ai_settings.realtimeButtonContent || "Generate")+" ")]):_vm._e()],1):_c('el-col',{ref:"colRef",style:(_vm.computedStyles),attrs:{"span":_vm.showLabel ? 4 : _vm.data.description ? 20 : 24}},[(_vm.hasLabel)?_c('span',{staticStyle:{"color":"white","display":"inline-block","height":"20px","opacity":"0"}}):_vm._e(),(_vm.data.validations.required && _vm.hasLabel && !_vm.isView)?_c('span',{staticStyle:{"color":"red","font-weight":"bold"}},[_vm._v(" * ")]):_vm._e()]),_c('el-col',{staticClass:"execute-view",attrs:{"span":_vm.showLabel && !_vm.isDefalutPos ? 18 : 20}},[(
          !_vm.isView &&
          _vm.isList &&
          _vm.data.inputType == 'MULTI_LINE_TEXT' &&
          _vm.data.properties.filed_content != 'Hide'
        )?_c('el-input',{style:({ 'text-align': _vm.inputTextAlignment(), ..._vm.getStyle }),attrs:{"type":"textarea","rows":_vm.data.validations.rows,"placeholder":_vm.data.placeholder || _vm.data.label,"resize":"none","readonly":true,"disabled":_vm.isFromDocument ? false : _vm.checkReadonly()},on:{"change":_vm.applyFormRules},model:{value:(_vm.form[_vm.data.key]),callback:function ($$v) {_vm.$set(_vm.form, _vm.data.key, $$v)},expression:"form[data.key]"}}):_vm._e(),(
          !_vm.isView &&
          _vm.data.inputType == 'MULTI_LINE_TEXT' &&
          !_vm.isList &&
          _vm.data.input_type != 'RICH_TEXT' &&
          _vm.data.properties.filed_content != 'Hide'
        )?_c('el-input',{class:{ 'focus-border-input': _vm.highlight },style:({ 'text-align': _vm.inputTextAlignment, ..._vm.getStyle }),attrs:{"rows":_vm.data.validations.rows,"type":"textarea","resize":"none","placeholder":_vm.data.placeholder || _vm.data.label,"disabled":_vm.isFromDocument ? false : _vm.checkReadonly(),"readonly":_vm.readonly()},on:{"change":_vm.applyFormRules},model:{value:(_vm.form[_vm.data.key]),callback:function ($$v) {_vm.$set(_vm.form, _vm.data.key, $$v)},expression:"form[data.key]"}}):_vm._e(),(_vm.data.input_type == 'RICH_TEXT' && !_vm.isInDataTable)?[(
            (!_vm.isView || _vm.isView == false) &&
            !_vm.data.is_entity_variable &&
            _vm.ckeditorConfigCompleted
          )?_c('ckeditor',{class:{ 'disabled-div': _vm.checkReadonly() },attrs:{"config":_vm.editorConfigWithOptions,"read-only":_vm.readonly()},on:{"input":function($event){return _vm.getMentionableFields()}},model:{value:(_vm.form[_vm.data.key]),callback:function ($$v) {_vm.$set(_vm.form, _vm.data.key, $$v)},expression:"form[data.key]"}}):_vm._e(),(
            (this.$route &&
              this.$route.name &&
              this.$route.name == 'ViewTemplate' &&
              _vm.data.is_entity_variable) ||
            (this.$route &&
              this.$route.name &&
              this.$route.name == 'PreviewEntityDataStep') ||
            _vm.data.is_entity_variable
          )?_c('p',{staticStyle:{"white-space":"pre-line"},style:(_vm.getStyle)},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.form[_vm.data.key])}})]):_vm._e()]:_vm._e(),(!_vm.isView && _vm.data.input_type == 'RICH_TEXT' && _vm.isInDataTable)?[(_vm.form[_vm.data.key])?_c('div',[_vm._v(" "+_vm._s(_vm._f("truncateHTML")(_vm.form[_vm.data.key],100))+" "),_c('el-link',{attrs:{"type":"primary"},on:{"click":_vm.showCkEditor}},[_vm._v("Edit/ View/ Add")])],1):_c('div',[_c('el-link',{attrs:{"type":"primary"},on:{"click":_vm.showCkEditor}},[_vm._v("Edit/ View/ Add")])],1)]:_vm._e(),(_vm.isView && _vm.data.input_type == 'RICH_TEXT' && _vm.isInDataTable)?_c('p',{staticStyle:{"white-space":"normal"},style:(_vm.getStyle)},[(_vm.form[_vm.data.key])?[_vm._v(" "+_vm._s(_vm._f("truncateHTML")(_vm.form[_vm.data.key],100))+" "),_c('el-link',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.showpreview(_vm.form[_vm.data.key])}}},[_vm._v(" Read more.. ")])]:[_vm._v("-")]],2):(
          _vm.isView && _vm.data.input_type == 'MULTI_LINE_TEXT' && _vm.isInDataTable
        )?_c('p',{staticStyle:{"white-space":"pre-line"},style:(_vm.getStyle)},[(_vm.form[_vm.data.key])?[_vm._v(" "+_vm._s(_vm._f("truncateHTML")(_vm.form[_vm.data.key],100))+" "),_c('el-link',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.showpreview(_vm.form[_vm.data.key])}}},[_vm._v(" Read more.. ")])]:[_vm._v("-")]],2):(
          _vm.isView && _vm.data.input_type == 'MULTI_LINE_TEXT' && !_vm.isInDataTable
        )?_c('p',{staticStyle:{"white-space":"pre-line","overflow-y":"auto","scrollbar-width":"thin"},style:(_vm.getStyle)},[(_vm.form[_vm.data.key])?[_vm._v(" "+_vm._s(_vm.form[_vm.data.key])+" ")]:[_vm._v("-")]],2):(_vm.isView && _vm.data.input_type == 'RICH_TEXT' && !_vm.isInDataTable && !_vm.data.is_entity_variable)?_c('span',{staticClass:"sidemenu",staticStyle:{"white-space":"pre-line"},style:(_vm.getStyle)},[(_vm.form[_vm.data.key])?_c('ckeditor',{class:{ 'disabled-div': _vm.checkReadonly() },attrs:{"config":_vm.editorConfig,"read-only":""},model:{value:(_vm.form[_vm.data.key]),callback:function ($$v) {_vm.$set(_vm.form, _vm.data.key, $$v)},expression:"form[data.key]"}}):[_vm._v("-")]],2):_vm._e()],2)],1),_c('dialog-component',{attrs:{"title":_vm.data.label,"isShowFooter":false,"visible":_vm.showEditor,"containerMinWidth":'67%',"containerMaxWidth":'85%',"containerMinHeight":'60%'},on:{"before-close":_vm.closeCkEditor}},[_c('div',{staticClass:"ckeditor-popup"},[((!_vm.isView || _vm.isView == false) && !_vm.data.is_entity_variable)?_c('ckeditor',{class:{ 'disabled-div': _vm.checkReadonly() },attrs:{"config":_vm.editorConfigWithOptions},model:{value:(_vm.form[_vm.data.key]),callback:function ($$v) {_vm.$set(_vm.form, _vm.data.key, $$v)},expression:"form[data.key]"}}):_vm._e(),(
          (this.$route &&
            this.$route.name &&
            this.$route.name == 'ViewTemplate' &&
            _vm.data.is_entity_variable) ||
          (this.$route &&
            this.$route.name &&
            this.$route.name == 'PreviewEntityDataStep') ||
          _vm.data.is_entity_variable
        )?_c('p',{staticStyle:{"white-space":"pre-line"},style:(_vm.getStyle)},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.form[_vm.data.key])}})]):_vm._e()],1)]),_c('dialog-component',{attrs:{"title":_vm.data.label,"isShowFooter":false,"visible":_vm.showTextPreview,"containerMinWidth":'70%',"containerMinHeight":'50%',"containerMaxWidth":'65%',"containerMaxHeight":'80vh'},on:{"before-close":_vm.closeTextPreview}},[_c('div',{staticClass:"text-preview"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.form[_vm.data.key])}})])])],1):_c('div',[(_vm.isView && _vm.data.properties.filed_content != 'Hide')?_c('div',[_c('span',{domProps:{"innerHTML":_vm._s(_vm.form[_vm.data.key])}})]):_c('div',[(
        _vm.data.inputType == 'MULTI_LINE_TEXT' &&
        _vm.data.properties.filed_content != 'Hide'
      )?_c('el-input',{style:({ 'text-align': _vm.inputTextAlignment(), ..._vm.getStyle }),attrs:{"type":"textarea","rows":_vm.data.validations.rows,"placeholder":_vm.data.placeholder || _vm.data.label,"resize":"none","disabled":_vm.isFromDocument ? false : _vm.checkReadonly()},on:{"change":_vm.applyFormRules},model:{value:(_vm.form[_vm.data.key]),callback:function ($$v) {_vm.$set(_vm.form, _vm.data.key, $$v)},expression:"form[data.key]"}}):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }